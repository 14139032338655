import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="StaySafe" link="https://staysafe.best" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  StaySafe is a browser based web application intended for use on mobile devices. Our application allows users to call for help on the go with ease, and keep tabs on their friends who are out and about.
  <img src="https://i.imgur.com/UuPvyyt.jpg" width="100%"></img>
    </ProjectCard>
    <ProjectCard title="Pet Adoption Web Services" link="http://paws.wtf" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  A web app designed to help rehome adoptable animals in your area.  Completed for Project 2 of the DU Web Development Bootcamp
  <img src="https://i.imgur.com/m3n52ZL.png" width="100%"></img>
    </ProjectCard>
    <ProjectCard title="Friend Finder" link="https://github.com/bradleyknutson/FriendFinder" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Friend Finder is a site used to find your closest friend! You are able to view the current list of all friends using the built-in API.
  <img src="https://i.imgur.com/NuW3YpE.png" width="100%"></img>
    </ProjectCard>
    <ProjectCard title="RPS Multiplayer" link="https://bradleyknutson.github.io/RPS-Multiplayer" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  A game of Rock Paper Scissors.  This multiplayer game is perfect for two friends who really think they shouldn't be friends anymore.
  <img src="https://i.imgur.com/hgJC0dA.png" width="100%"></img>
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      