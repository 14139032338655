import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Welcome all, to the world of Me!`}</p>
    </blockquote>
    <p>{`I am a burgeoning developer and entrepreneur. Starting late 2019, I became a student at the DU coding bootcamp focusing on web development using the MERN stack. It has always been a dream of mine to be a programmer and I have hit my jump off point. I have experience with web development (HTML, CSS, Javascript, etc) as well as programming using Python, C++, and bit of C#.`}</p>
    <p>{`My side projects consist of using various Raspberry Pis for different objectives such as a Pi Hole, which is used for blocking advertisements at the network level as opposed to in a browser or application. I am also writing plans for a full arcade cabinet using a new Raspberry Pi to emulate as many games as possible, both in terms of classic arcade and older generation console games such as the Sega Genesis.`}</p>
    <p>{`On a normal day you'd find me playing video games, reading a science fiction novel, riding my motorcycle (Kawasaki Z1000) or just sitting back and relaxing with my collection of Rubik's Cubes. I have a unique talent for puzzles and brain teasers and thus spend a decent amount of my time looking for new challenges.`}</p>
    <h2>{`Resume`}</h2>
    <h5>{`Download `}<a href="https://drive.google.com/file/d/1GQcz9Ae0vVuo7yeZjWO8dowoFC1CsJya/view?usp=sharing">{`HERE`}</a></h5>
    <h4>{`Summary`}</h4>
    <p>{`Accomplished IT Manager and junior developer with over 5 years of experience.  Has started from the bottom as a level one support specialist and, through increasing eﬀectiveness of the individual processes, managed to move to a managerial role.  Reduced response time to below one minute while maintaining a nearly perfect resolution rate.  Passionate and excited for all new possibilities.  `}</p>
    <h4>{`Employment`}</h4>
    <h5>{`CommercialTribe`}</h5>
    <h6>{`IT Manager - Oct. 2017 to Current`}</h6>
    <p>{`As the IT Manager I fulﬁll all needs and requirements from employees within the company for technological needs.  I provision and maintain our array of machines and peripherals as well as monitor and maintain our local network.  As part of my job I am also the lead for our external support team, including the hiring of workers and writing of the associated documentation.`}</p>
    <h6>{`IT Engineer - Oct. 2015 to Oct. 2017`}</h6>
    <p>{`As an IT Engineer, I worked both internal and external tickets to remediate all technological issues for employees and customers alike.  The bulk of my work was in troubleshooting the CommercialTribe application in a browser.  When bugs were found I had to ﬁnd steps to recreate the issue and send tickets to our development team to resolve.`}</p>
    <h5>{`IBM`}</h5>
    <h6>{`Level 1 Support Speialist - May 2013 to Oct. 2015`}</h6>
    <p>{`As a support specialist, I answered calls and chats to help resolve issues for employees at American Express.  The calls regarded anything from an ADS password reset to a halted server.  I responded accordingly and accurately to each one according to the severity and available resources.  I was also a QA specialist for the other agents and listened to randomly selected calls to grade them on required scripts.`}</p>
    <h4>{`Education`}</h4>
    <p>{`University of Denver Sept. 2019 to Current`}</p>
    <h4>{`Skills`}</h4>
    <h5>{`Industry Knowledge`}</h5>
    <ul>
      <li parentName="ul">{`IT Operations`}<br></br></li>
      <li parentName="ul">{`Network Administration`}<br></br></li>
      <li parentName="ul">{`Network Security`}<br></br></li>
      <li parentName="ul">{`Project Management`}<br></br></li>
      <li parentName="ul">{`Penetration troubleshooting`}<br></br></li>
      <li parentName="ul">{`Full Stack development`}<br></br></li>
    </ul>
    <h5>{`Tools & Technologies`}</h5>
    <ul>
      <li parentName="ul">{`Javascript`}<br></br></li>
      <li parentName="ul">{`MongoDB`}<br></br></li>
      <li parentName="ul">{`SQL`}<br></br></li>
      <li parentName="ul">{`Wireshark`}<br></br></li>
      <li parentName="ul">{`NMap`}<br></br></li>
      <li parentName="ul">{`MacOS`}<br></br></li>
      <li parentName="ul">{`Windows`}<br></br></li>
      <li parentName="ul">{`MS Office`}<br></br></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      